import { Box, Text, Flex, Heading, Image, Button, SimpleGrid, VStack, Fade, ScaleFade, useToast } from '@chakra-ui/react';
import { BsDroplet, BsHeart, BsChat } from "react-icons/bs";
import { SiTelegram } from 'react-icons/si';
import { sendLandingPageViewed, sendStartChatClicked } from '../telemetry';
import { useEffect, useState } from 'react';

const backgroundColor1 = "radial-gradient(circle, purple.300 30%, white 75%)";
const backgroundColor2 = "radial-gradient(circle, pink.300 60%, white 75%)";

const START_CHATTING = "¡Chat Gratis Ahora!";

export const TELEGRAM_BOT_URL = "https://t.me/DreamGFXXXBot?start=senoritafun";

export const handleClick = (toast) => {
  sendStartChatClicked();
  toast({
    title: "¡Felicidades!",
    description: "Has desbloqueado un chat VIP. ¡Disfruta!",
    status: "success",
    duration: 5000,
    isClosable: true,
  });
};

const girls = [
  { name: "María", age: 24, description: "Apasionada y aventurera", image: "/girl1.png" },
  { name: "Sofía", age: 22, description: "Dulce y romántica", image: "/girl2.png" },
  { name: "Valentina", age: 26, description: "Inteligente y misteriosa", image: "/girl3.png" },
  { name: "Camila", age: 23, description: "Divertida y espontánea", image: "/girl4.png" },
  { name: "Lucía", age: 25, description: "Elegante y sofisticada", image: "/girl5.png" },
  { name: "Gabriela", age: 21, description: "Creativa y soñadora", image: "/girl6.png" },
  { name: "Isabella", age: 27, description: "Carismática y extrovertida", image: "/girl7.png" },
  { name: "Ana", age: 20, description: "Tímida y encantadora", image: "/girl8.png" },
  { name: "Paula", age: 28, description: "Ambiciosa y determinada", image: "/girl9.png" },
  { name: "Daniela", age: 24, description: "Atlética y enérgica", image: "/girl10.png" },
  { name: "Fernanda", age: 23, description: "Artística y bohemia", image: "/girl11.png" },
  { name: "Catalina", age: 25, description: "Intelectual y curiosa", image: "/girl12.png" },
];

function Home() {
  const [showContent, setShowContent] = useState(false);
  const toast = useToast();

  useEffect(() => {
    sendLandingPageViewed();
    setShowContent(true);
    
    const timer = setTimeout(() => {
      toast({
        title: "¡Oferta especial!",
        description: "¡Chatea gratis con cualquier chica durante los próximos 5 minutos!",
        status: "success",
        duration: null,
        isClosable: true,
        position: "top",
      });
    }, 10000);

    return () => clearTimeout(timer);
  }, [toast]);

  return (
    <Box
      minH="100vh"
      backgroundImage={`${backgroundColor1}, ${backgroundColor2}`}
      backgroundBlendMode="overlay"
      textAlign="center"
      fontSize={{ base: "sm", md: "md" }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        minH="100vh"
        flexDirection="column"
        padding={{ base: "10", md: "20" }}
      >
        <Fade in={showContent} delay={0.2}>
          <Box
            borderRadius="full"
            px={{ base: 3, md: 6 }}
            py={{ base: 1, md: 3 }}
            boxShadow="md"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            border="1px solid"
            borderColor="gray.700"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Flex alignItems="center" justifyContent="center">
              <BsDroplet fontSize={{ base: "xl", md: "2xl" }} color="gray.700" />
              <Text
                fontSize={{ base: "lg", md: "xl" }}
                color="gray.700"
                mr={{ base: 1, md: 2 }}
                ml={{ base: 1, md: 2 }}
                fontWeight="bold"
              >
                Señorita.fun
              </Text>
            </Flex>
          </Box>
        </Fade>
        <ScaleFade initialScale={0.9} in={showContent} delay={0.4}>
          <Heading
            color="gray.700"
            mt={{ base: 4, md: 6 }}
            size={{ base: "2xl", md: "4xl" }}
          >
            ¡Tu aventura comienza ahora!
          </Heading>
        </ScaleFade>
        <Fade in={showContent} delay={0.6}>
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            color="gray.700"
            mt={{ base: 3, md: 5 }}
            mb={{ base: 3, md: 5 }}
          >
            Chicas hermosas están esperando para chatear contigo
          </Text>
        </Fade>
        <ScaleFade initialScale={0.9} in={showContent} delay={0.8}>
          <Button
            as="a"
            href={TELEGRAM_BOT_URL}
            target="_blank"
            rel="noopener noreferrer"
            bg="pink.400"
            color="white"
            width={{ base: "220px", md: "300px" }}
            height={{ base: "60px", md: "90px" }}
            px={{ base: 4, md: 8 }}
            fontSize={{ base: "lg", md: "2xl" }}
            rightIcon={<SiTelegram />}
            _hover={{
              bg: "purple.400",
              transform: "translateY(-5px)",
              boxShadow: "xl",
            }}
            borderRadius="full"
            mb={{ base: "6", md: "14" }}
            onClick={() => handleClick(toast)}
            transition="all 0.3s"
          >
            {START_CHATTING}
          </Button>
        </ScaleFade>
        <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={10} mb="50px">
          {girls.map((girl, index) => (
            <ScaleFade initialScale={0.9} in={showContent} delay={0.2 * (index + 1)} key={index}>
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                overflow="hidden" 
                bg="rgba(255, 255, 255, 0.8)"
                transition="all 0.3s"
                _hover={{
                  transform: "translateY(-10px)",
                  boxShadow: "xl",
                }}
              >
                <Image src={girl.image} alt={girl.name} height="200px" width="100%" objectFit="cover" />
                <VStack p="4" align="start">
                  <Heading as="h3" size="md" color="gray.700">{girl.name}</Heading>
                  <Text fontSize="sm" color="gray.600">{girl.age} años</Text>
                  <Text fontSize="xs" color="gray.600">{girl.description}</Text>
                  <Flex justify="space-between" width="100%" mt="2">
                    <Button
                      as="a"
                      href={TELEGRAM_BOT_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      leftIcon={<BsHeart />}
                      size="sm"
                      variant="outline"
                      colorScheme="pink"
                      onClick={() => handleClick(toast)}
                    >
                      Me gusta
                    </Button>
                    <Button
                      as="a"
                      href={TELEGRAM_BOT_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      leftIcon={<BsChat />}
                      size="sm"
                      variant="solid"
                      colorScheme="purple"
                      onClick={() => handleClick(toast)}
                    >
                      Chatear
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
}

export default Home;