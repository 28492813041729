import React from 'react';
import { Box, Heading, Text, SimpleGrid, Button, useToast } from '@chakra-ui/react';
import { handleClick, TELEGRAM_BOT_URL } from './Home';

const ChicasPorPais = () => {
  const paises = ['Colombia', 'Venezuela', 'Argentina', 'Brasil', 'México', 'Perú'];
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Chicas por País
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Explora la diversidad y belleza de las chicas latinas por país. Cada nación tiene su encanto único.
      </Text>
      
      <SimpleGrid columns={[2, 3, 3]} spacing="20px" mb="10">
        {paises.map((pais, index) => (
          <Button
          key={index}
          colorScheme="pink"
          size="lg"
          as="a"
          href={TELEGRAM_BOT_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(toast)}>
            Chicas de {pais}
          </Button>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ChicasPorPais;