import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ChicasLatinas from './components/ChicasLatinas';
import ChatEroticoGratis from './components/ChatEroticoGratis';
import WebcamEnVivo from './components/WebcamEnVivo';
import CitasVirtuales from './components/CitasVirtuales';
import ChicasPorPais from './components/ChicasPorPais';
import ComoFunciona from './components/ComoFunciona';
import Testimonios from './components/Testimonios';
import Blog from './components/Blog';
import Profile from './components/Profile';
import Policy from './components/Policy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ChatEroticoGratis" element={<ChatEroticoGratis />} />
        <Route path="/ChicasLatinas" element={<ChicasLatinas />} />
        <Route path="/ChicasPorPais" element={<ChicasPorPais />} />
        <Route path="/CitasVirtuales" element={<CitasVirtuales />} />
        <Route path="/ComoFunciona" element={<ComoFunciona />} />
        <Route path="/WebcamEnVivo" element={<WebcamEnVivo />} />
        <Route path="/Testimonios" element={<Testimonios />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Policy" element={<Policy />} />
      </Routes>
    </Router>
  );
}

export default App;
