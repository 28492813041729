import React from 'react';
import { Box, Heading, Text, VStack, OrderedList, ListItem } from '@chakra-ui/react';

const ComoFunciona = () => {
  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Cómo Funciona Señorita.fun
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Descubre lo fácil que es conectar con hermosas chicas latinas en nuestra plataforma.
      </Text>
      
      <VStack spacing="6" align="stretch">
        <OrderedList spacing={4}>
          <ListItem>Regístrate gratis en nuestra plataforma</ListItem>
          <ListItem>Explora perfiles de chicas latinas</ListItem>
          <ListItem>Inicia un chat o una videollamada</ListItem>
          <ListItem>Disfruta de la interacción y experiencias únicas</ListItem>
        </OrderedList>
      </VStack>
    </Box>
  );
};

export default ComoFunciona;