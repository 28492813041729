import React from 'react';
import { Box, Heading, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react';

const Policy = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding="20px">
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="2xl" textAlign="center">
          Políticas de Señorita.fun
        </Heading>
        
        <Box>
          <Heading size="lg" mb={2}>Política de Privacidad</Heading>
          <Text>En Señorita.fun, valoramos y protegemos tu privacidad. Nuestra política incluye:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Protección de datos personales</ListItem>
            <ListItem>No compartimos información con terceros</ListItem>
            <ListItem>Opciones de control sobre tu información</ListItem>
          </UnorderedList>
        </Box>
        
        <Box>
          <Heading size="lg" mb={2}>Términos de Servicio</Heading>
          <Text>Al usar nuestro servicio, aceptas cumplir con nuestros términos, que incluyen:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Ser mayor de 18 años</ListItem>
            <ListItem>No usar lenguaje ofensivo o abusivo</ListItem>
            <ListItem>Respetar a otros usuarios y modelos</ListItem>
          </UnorderedList>
        </Box>
        
        <Box>
          <Heading size="lg" mb={2}>Política de Cookies</Heading>
          <Text>Utilizamos cookies para mejorar tu experiencia en nuestro sitio. Esto nos permite:</Text>
          <UnorderedList pl={4} mt={2}>
            <ListItem>Recordar tus preferencias</ListItem>
            <ListItem>Ofrecer contenido personalizado</ListItem>
            <ListItem>Analizar el tráfico del sitio</ListItem>
          </UnorderedList>
        </Box>
      </VStack>
    </Box>
  );
};

export default Policy;