import React from 'react';
import { Box, Heading, Text, VStack, Avatar, Flex } from '@chakra-ui/react';

const Testimonio = ({ nombre, texto, imagen }) => (
  <Box borderWidth="1px" borderRadius="lg" p={4}>
    <Flex align="center" mb={4}>
      <Avatar src={imagen} mr={4} />
      <Heading size="md">{nombre}</Heading>
    </Flex>
    <Text>{texto}</Text>
  </Box>
);

const Testimonios = () => {
  const testimonios = [
    { nombre: "Carlos", texto: "Increíble experiencia. Las chicas son hermosas y muy amables.", imagen: "/avatar1.jpg" },
    { nombre: "Juan", texto: "Me encanta la variedad de chicas y lo fácil que es usar la plataforma.", imagen: "/avatar2.jpg" },
    { nombre: "Miguel", texto: "Las citas virtuales son geniales. Me siento como si estuviera realmente allí.", imagen: "/avatar3.jpg" },
  ];

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Testimonios de Nuestros Usuarios
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Descubre lo que nuestros usuarios dicen sobre sus experiencias en Señorita.fun
      </Text>
      
      <VStack spacing={6}>
        {testimonios.map((t, index) => (
          <Testimonio key={index} nombre={t.nombre} texto={t.texto} imagen={t.imagen} />
        ))}
      </VStack>
    </Box>
  );
};

export default Testimonios;