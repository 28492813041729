import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Button, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ChicasLatinas = () => {
  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Chicas Latinas Sexys en Señorita.fun
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Descubre la pasión y belleza de las chicas latinas más sensuales en nuestro chat erótico. Conéctate con hermosas mujeres de toda Latinoamérica y vive experiencias únicas.
      </Text>
      
      <SimpleGrid columns={[1, 2, 3]} spacing="20px" mb="10">
        {/* Aquí puedes agregar imágenes de chicas latinas */}
        <Image src="girl1.png" alt="Chica latina sexy" borderRadius="md" />
        <Image src="girl2.png" alt="Hermosa latina en webcam" borderRadius="md" />
        <Image src="girl3.png" alt="Modelo latina sensual" borderRadius="md" />
      </SimpleGrid>
      
      <VStack spacing="6" align="stretch">
        <Heading as="h2" size="xl">
          ¿Por qué elegir chicas latinas?
        </Heading>
        <Text fontSize="lg">
          Las chicas latinas son conocidas por su belleza exótica, personalidad apasionada y carisma único. En Señorita.fun, podrás disfrutar de:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Belleza natural y curvas sensuales</li>
            <li>Personalidades cálidas y acogedoras</li>
            <li>Experiencias culturales diversas</li>
            <li>Conversaciones picantes en español</li>
            <li>Shows en vivo llenos de pasión latina</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button as={Link} to="/ChatEroticoGratis" colorScheme="pink" size="lg">
          Chatea gratis ahora con chicas latinas
        </Button>
      </Box>
    </Box>
  );
};

export default ChicasLatinas;