import React from 'react';
import { Box, Heading, Text, VStack, Image, Button } from '@chakra-ui/react';

const Profile = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding="20px">
      <VStack spacing={6} align="stretch">
        <Image src="/profile-pic.jpg" alt="Foto de perfil" borderRadius="full" boxSize="200px" alignSelf="center" />
        
        <Heading as="h1" size="2xl" textAlign="center">
          Tu Perfil
        </Heading>
        
        <Box>
          <Heading size="md" mb={2}>Información Personal</Heading>
          <Text>Nombre: Juan Pérez</Text>
          <Text>Edad: 28</Text>
          <Text>Ubicación: Ciudad de México</Text>
        </Box>
        
        <Box>
          <Heading size="md" mb={2}>Preferencias</Heading>
          <Text>Intereses: Chicas latinas, webcam en vivo</Text>
          <Text>Idiomas: Español, Inglés</Text>
        </Box>
        
        <Button colorScheme="pink">Editar Perfil</Button>
      </VStack>
    </Box>
  );
};

export default Profile;