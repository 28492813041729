import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const BlogPost = ({ titulo, resumen, imagen }) => (
  <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
    <Image src={imagen} alt={titulo} />
    <Box p={4}>
      <Heading size="md" mb={2}>{titulo}</Heading>
      <Text mb={4}>{resumen}</Text>
      <Button as={Link} to="#" colorScheme="pink">Leer más</Button>
    </Box>
  </Box>
);

const Blog = () => {
  const posts = [
    { titulo: "Cómo tener una cita virtual exitosa", resumen: "Consejos para que tu cita virtual sea inolvidable...", imagen: "/blog1.jpg" },
    { titulo: "Las mejores poses para tus fotos de perfil", resumen: "Aprende a lucir increíble en tus fotos...", imagen: "/blog2.jpg" },
    { titulo: "Temas de conversación para romper el hielo", resumen: "Inicia conversaciones interesantes con estos temas...", imagen: "/blog3.jpg" },
  ];

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Blog de Señorita.fun
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Consejos, trucos y noticias sobre el mundo de las citas virtuales y el chat erótico.
      </Text>
      
      <SimpleGrid columns={[1, 2, 3]} spacing={6}>
        {posts.map((post, index) => (
          <BlogPost key={index} titulo={post.titulo} resumen={post.resumen} imagen={post.imagen} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Blog;